// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-resume-js": () => import("./../../../src/pages/about/resume.js" /* webpackChunkName: "component---src-pages-about-resume-js" */),
  "component---src-pages-about-skills-services-js": () => import("./../../../src/pages/about/skills-services.js" /* webpackChunkName: "component---src-pages-about-skills-services-js" */),
  "component---src-pages-alt-index-js": () => import("./../../../src/pages/alt-index.js" /* webpackChunkName: "component---src-pages-alt-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-index-2-js": () => import("./../../../src/pages/legacy-index2.js" /* webpackChunkName: "component---src-pages-legacy-index-2-js" */),
  "component---src-pages-legacy-index-js": () => import("./../../../src/pages/legacy-index.js" /* webpackChunkName: "component---src-pages-legacy-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-portfolio-category-js": () => import("./../../../src/templates/portfolio-category.js" /* webpackChunkName: "component---src-templates-portfolio-category-js" */),
  "component---src-templates-portfolio-project-js": () => import("./../../../src/templates/portfolio-project.js" /* webpackChunkName: "component---src-templates-portfolio-project-js" */)
}

